import {ExportOutlined} from '@ant-design/icons';
import Auth from 'Auth';
import {Button, Comment, Divider, Form, Input, Tooltip} from 'antd';
import {useSetSearchTokenMutation} from 'api/searchSlice';
import axios from 'axios';
import Artifacts from 'components/search/Artifacts';
import {TRACK_API_BASE_URL} from 'consts';
import {handleApiError} from 'errorHandler';
import React, {useEffect, useRef, useState} from 'react';

const ChatMessage = ({message}) => {
  console.log(message);
  return (
    <Comment
      style={{
        alignContent: message.author === 'You' ? 'end' : 'start',
        alignSelf: message.author === 'You' ? 'flex-end' : 'flex-start',
        backgroundColor: message.author === 'You' ? '#ffdd00' : '#f0f0f0',
        border: '1px solid #f0f0f0',
        borderRadius: '5px',
        margin: '0 20px',
        padding: '0 10px',
        width: 'fit-content',
      }}
      author={message.author}
      content={
        message.artifacts?.length ? (
          <div>
            <p>{message.preamble}</p>
            {message.artifacts.map((artifact) => (
              <Tooltip key={artifact.id} title={<p>{artifact.content}</p>}>
                <p>
                  {artifact.footnoteNumber}
                  {' - '}
                  {artifact.name}{' '}
                  <a
                    key={artifact.id}
                    href={artifact.source}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExportOutlined />
                  </a>
                </p>
              </Tooltip>
            ))}
            <Divider />
            <p>{message.content}</p>
          </div>
        ) : (
          <p>{message.content}</p>
        )
      }
      datetime={message.time}
      key={message.content}
    />
  );
};

const xhrAxios = axios.create({
  adapter: 'fetch',
});

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [infoItem, setInfoItem] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [artifacts, setArtifacts] = useState([]);

  const [form] = Form.useForm();

  const containerRef = useRef(null);
  const newMessageRef = useRef(null);

  const [setToken, {data: token}] = useSetSearchTokenMutation();

  useEffect(() => {
    setToken();
  }, []);

  const scrollToBottom = () => {
    const element = containerRef.current;
    const newMessageElement = newMessageRef.current;
    if (element) {
      let height = 0;
      if (newMessageElement) {
        height = newMessageElement.offsetHeight;
      }
      element.scrollTo({
        top: element.scrollHeight + height,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setMessageText(newMessageRef?.current?.innerText);
    });

    if (newMessageRef.current) {
      observer.observe(newMessageRef.current, {
        childList: true, // for text changes
        subtree: true, // if there are nested elements
        characterData: true, // for character data changes
      });
    }

    return () => {
      observer.disconnect(); // Cleanup observer on component unmount
    };
  }, []);

  useEffect(() => {
    // scroll to bottom when messages change
    scrollToBottom();
  }, [messages, messageText]);

  const streamResponse = async (decoder, reader, newMessages) => {
    const newMessage = {
      author: 'Bot',
      id: newMessages.length,
      time: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
    };
    newMessageRef.current.innerText = 'Processing...';

    let buffer = '';
    let receivedText = '';

    while (true) {
      const {done, value} = await reader.read();
      if (done) break;

      // Decode the incoming chunk
      const decoded = decoder.decode(value, {stream: true});
      // Add the decoded chunk to the buffer
      buffer += decoded;

      let boundary = 0;
      while ((boundary = buffer.indexOf('\n')) !== -1) {
        const jsonString = buffer.slice(0, boundary).trim();
        buffer = buffer.slice(boundary + 1);

        if (jsonString) {
          try {
            // Parse the JSON string
            const decodedJSON = JSON.parse(jsonString);

            // Update the receivedText with the content
            if (decodedJSON.type === 'ai') {
              receivedText += decodedJSON.content;
              newMessage.content = receivedText;
              newMessageRef.current.innerText = receivedText;
            } else if (decodedJSON.type === 'system') {
              setInfoItem(decodedJSON);
            }
          } catch (error) {
            console.error('Failed to parse JSON:', error);
          }
        }
      }
    }

    // Handle any remaining buffered data that didn't end with a newline
    if (buffer) {
      try {
        if (buffer.trim() === '') {
          return;
        }
        const decodedJSON = JSON.parse(buffer.trim());
        receivedText += decodedJSON.content;
        newMessage.content = receivedText;
        newMessageRef.current.innerText = receivedText;
      } catch (error) {
        console.error('Failed to parse remaining JSON:', error);
      }
    }

    console.log('receivedText', receivedText);

    const newMessagePreamble = newMessage.content
      .match(/^[\S\s]*?(?=(\n\[\d\]))/)?.[0]
      ?.trim();
    const newMessageArtifacts = [];
    // anything after \n+++++++++++++\n is the main content
    const newMessageContent = newMessage.content
      .match(/(?<=\n\n\+{13}\n\n)([\s\S]*)/)?.[0]
      ?.trim();

    // extract any artifacts from the message and add them to the artifacts list
    const linkRegex =
      /\[\d+\].+?[a-z]+\/d\/[a-zA-Z0-9-_]+([^\|]+\s\|\s){3}[\S\s]*?(?=\n(\[\d\])|(\+{13}))/g;

    let match;
    // const matches = newMessage.content.matchAll(linkRegex);
    while ((match = linkRegex.exec(receivedText)) !== null) {
      console.log('match', match);
      // match each component of the regex
      const footnoteNumber = match[0].match(/(?<=\[)\d(?=\])/)[1];
      const sourceLink = match[0].match(/http.+?[a-z]+\/d\/[a-zA-Z0-9-_]+/)[0];
      const sourceType = sourceLink.split('/')[3];
      const sourceId = sourceLink.split('/')[5];
      const sourceName = match[0].match(/(?<=\|\s)([\s\w]+)\.\w+(?=\s\|)/)[0];
      const sourceContent = match[0].match(/(?<=\|\s\(\d\):\s)([^\|]+)$/)[0];

      const artifact = {
        id: `${sourceId}-${footnoteNumber}`,
        source: `${sourceLink}/${sourceType === 'document' ? 'preview' : 'htmlview'}`,
        name: sourceName,
        footnoteNumber,
        content: sourceContent,
      };
      newMessageArtifacts.push(artifact);
      setArtifacts((artifacts) => [...artifacts, artifact]);
    }

    // parse the message to display it without metadata
    newMessage.preamble = newMessagePreamble;
    newMessage.artifacts = newMessageArtifacts;
    newMessage.content = newMessage.artifacts
      ? newMessageContent
      : newMessage.content;

    setInfoItem(null);
    newMessages.push(newMessage);
    // unset the new message ref
    newMessageRef.current.innerText = '';
    // refocus on the input field
    form.getFieldInstance('message').focus();
  };

  const sendMessage = (e) => {
    const yourMessageIndex = messages.length;
    const newMessages = [...messages];
    newMessages[yourMessageIndex] = {
      author: 'You',
      content: e.message,
      id: yourMessageIndex,
      time: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
    };
    setInfoItem({
      type: 'system',
      content: 'Processing...',
    });
    setMessages(newMessages);
    form.resetFields();

    const urlEncodedMessage = encodeURIComponent(e.message);
    xhrAxios
      .get(
        `${TRACK_API_BASE_URL}/search/chatbot?message=${urlEncodedMessage}&session=${token}`,
        {
          responseType: 'stream',
          headers: {
            Authorization: Auth.getToken(),
          },
        }
      )
      .then((response) => {
        const stream = response.data;
        const reader = stream.getReader();
        const decoder = new TextDecoder();
        streamResponse(decoder, reader, newMessages);
      })
      .catch((error) => {
        console.error('Failed to send message:', error);
        setInfoItem({
          type: 'system',
          content: 'Failed to send message',
        });
        handleApiError(error);
      });
  };

  return (
    <div className="flex-row" style={{height: '80vh'}}>
      <div className="flex-column" style={{height: '80vh', flex: 1}}>
        <div
          style={{flex: 1, overflowY: 'auto'}}
          className="flex-column"
          ref={containerRef}
        >
          {messages.map((message) => (
            <ChatMessage key={message.id} message={message} />
          ))}
          <Comment
            style={{
              alignContent: 'start',
              alignSelf: 'flex-start',
              backgroundColor: '#f0f0f0',
              border: '1px solid #ffdd00',
              borderRadius: '5px',
              display: newMessageRef?.current?.innerText ? 'block' : 'none',
              margin: '0 20px',
              padding: '0 10px',
              width: 'fit-content',
            }}
            author="Bot"
            content={<p ref={newMessageRef} />}
            datetime={new Date().toLocaleTimeString([], {timeStyle: 'short'})}
          />
          <div style={{flex: 1}} />
        </div>
        {infoItem && <div>{infoItem.content}</div>}
        <Form
          className="flex-row"
          form={form}
          name="control-hooks"
          onFinish={sendMessage}
          style={{width: '100%', margin: '10px 0'}}
        >
          <Form.Item
            name="message"
            rules={[{required: true, message: 'Please type a message'}]}
            style={{flex: 1, marginBottom: 0}}
          >
            <Input placeholder="Type a message..." size="large" />
          </Form.Item>

          <Form.Item style={{marginBottom: 0}}>
            <Button type="primary" htmlType="submit" size="large">
              Send
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Artifacts artifacts={artifacts} />
    </div>
  );
};

export default Chat;
